const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const GET_ALL_BOX = `${BASE_API_URL}/box/get_all_box/:subOrgId`;
export const GET_STAGE_LEADS = `${BASE_API_URL}/leads/list_lead_in_stage/:stageId`;
export const GET_REPLY_LATER_STAGE_LEADS = `${BASE_API_URL}/leads/list_lead_in_stage/:stageId`;
export const MOVE_TO_BOX = `${BASE_API_URL}/box/move_card_to_box`;
export const MOVE_CARD_TO_STAGE = `${BASE_API_URL}/box/move_card_stage`;
export const DELETE_BOX = `${BASE_API_URL}/box/delete_leads_in_box/:boxId`;
export const RENAME_BOX = `${BASE_API_URL}/box/update_box_name/:boxId`;
export const DOWNLOAD_LEADS_BEFORE_DELETE = `${BASE_API_URL}/box/sub_organisations/:subOrgId/boxs/:boxId/download_leads_before_deleting`;

// api for box form in box settings
export const GET_BOX_FORM_DATA = `${BASE_API_URL}/box/create_update_box_form/:boxId`;
export const CREATE_UPDATE_BOX_FORM = `${BASE_API_URL}/box/create_update_box_form`;

// api for gettings individual box data
export const GET_BOX_DATA = `${BASE_API_URL}/box/box_details/:boxId`;

// api for adding manual enquiry from box form
export const CREATE_MANUAL_ENQUIRY_BOX_FORM = `${BASE_API_URL}/box/add_manual_enquiry`;
export const ADD_STAGE = `${BASE_API_URL}/box/create_update_stage`;
export const UPDATE_STAGE = `${BASE_API_URL}/box/create_update_stage/:stageId`;
export const MOVE_TO_STAGE_API = `${BASE_API_URL}/box/move_card_to_stage`;
export const DELETE_STAGE = `${BASE_API_URL}/box/box_details/:stageId`;

// api for box automations
export const CREATE_BOX_AUTOMATION = `${BASE_API_URL}/automations/create_update_box_automation`;
export const UPDATE_BOX_AUTOMATION = `${BASE_API_URL}/automations/create_update_box_automation/:automation_id`;

// api to get stage contract value
export const GET_STAGE_CONTRACT_VALUE = `${BASE_API_URL}/box/get_stage_contract_value/:stageId`;

// api to get boxes new enquires
export const GET_NEW_BOXES = `${BASE_API_URL}/leads/list_new_leads_in_box/:boxId`;
export const GET_ALL_STAGE_LEAD_COUNT = `${BASE_API_URL}/box/:boxId/get_all_stages_with_lead_count`;

//api to configure box notification for user
export const GET_BOX_NOTIFICATION_OF_USER = `${BASE_API_URL}/box/users/:userId/box_notifications`;
export const UPDATE_BOX_NOTIFICATION_OF_USER = `${BASE_API_URL}/box/box_notifications/:boxNotificationId`;

// api for box drag and drop
export const RE_ORDER_BOX = `${BASE_API_URL}/box/sub_organisations/:subOrgId/reorder_box`;

// api for updating box data
export const UPDATE_BOX_DATA = `${BASE_API_URL}/box/update_box/:boxId`;

// api to hide videos in box and smart rules
export const HIDE_VIDEO = `${BASE_API_URL}/box/hide_tutorial_video`;

// api to dismiss dummy leads in box
export const DISMISS_DUMMY_LEADS = `${BASE_API_URL}/leads/delete_box_dummy_lead/:leadId`;
