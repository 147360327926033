import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    showImagePreview: false,
    imagePreview: null,
    previewImageIndex: null,
    isFileLibrary: false
  },
  reducers: {
    setItem: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setImagePreview: (state, action) => {
      state.imagePreview = action.payload;
    }
  }
});

export const { setNumber, increment, decrement, setItem, setImagePreview } =
  appSlice.actions;

export default appSlice.reducer;
