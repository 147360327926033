import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { FEED_TASK, LEAD_UPDATE_TASK, LEAD_UPDATE_TASK_STATUS } from 'urls';
import moment from 'moment';
import { replaceUrl } from 'utils/urlReplace';
import { isEmpty } from 'utils/utils';

export const feedTaskSlice = createSlice({
  name: 'feedTask',
  initialState: {
    overdueTask: [],
    overdueTodayTask: [],
    dueTomorrowTask: [],
    dueFuture: [],
    isEmptyOverdueTask: false,
    isEmptyOverdueTodayTask: false,
    isEmptyDueTomorrowTask: false,
    isEmptyDueFuture: false
  },
  reducers: {
    setOverdueTask: (state, action) => {
      state.overdueTask = action.payload;
    },
    setOverdueTodayTask: (state, action) => {
      state.overdueTodayTask = action.payload;
    },
    setDueTomorrowTask: (state, action) => {
      state.dueTomorrowTask = action.payload;
    },
    setDueFutureTask: (state, action) => {
      state.dueFuture = action.payload;
    },
    setIsEmptyOverdueTask: (state, action) => {
      state.isEmptyOverdueTask = action.payload;
    },
    setIsEmptyOverdueTodayTask: (state, action) => {
      state.isEmptyOverdueTodayTask = action.payload;
    },
    setIsEmptyDueTomorrowTask: (state, action) => {
      state.isEmptyDueTomorrowTask = action.payload;
    },
    setIsEmptyDueFutureTask: (state, action) => {
      state.isEmptyDueFuture = action.payload;
    }
  }
});

export const {
  setOverdueTask,
  setOverdueTodayTask,
  setDueTomorrowTask,
  setDueFutureTask,
  setIsEmptyOverdueTask,
  setIsEmptyOverdueTodayTask,
  setIsEmptyDueTomorrowTask,
  setIsEmptyDueFutureTask
} = feedTaskSlice.actions;

export const getOverdueTask = (payload) => {
  return (dispatch) => {
    try {
      const { userId, callback, query = {} } = payload;
      new APIRequest()
        .get(FEED_TASK, {
          assigned_to: userId,
          over_due: moment().format('YYYY-MM-DD'),
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setOverdueTask(resp.data));
            if (isEmpty(resp.data)) {
              dispatch(setIsEmptyOverdueTask(true));
            } else {
              dispatch(setIsEmptyOverdueTask(false));
            }
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getOverdueTodayTask = (payload) => {
  return (dispatch) => {
    try {
      const { userId, callback, query = {} } = payload;
      new APIRequest()
        .get(FEED_TASK, {
          assigned_to: userId,
          due_date: moment().format('YYYY-MM-DD'),
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setOverdueTodayTask(resp.data));
            if (isEmpty(resp.data)) {
              dispatch(setIsEmptyOverdueTodayTask(true));
            } else {
              dispatch(setIsEmptyOverdueTodayTask(false));
            }
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getDueTomorrowTask = (payload) => {
  return (dispatch) => {
    try {
      const { userId, callback, query = {} } = payload;
      new APIRequest()
        .get(FEED_TASK, {
          assigned_to: userId,
          due_date: moment().add(1, 'days').format('YYYY-MM-DD'),
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setDueTomorrowTask(resp.data));
            if (isEmpty(resp.data)) {
              dispatch(setIsEmptyDueTomorrowTask(true));
            } else {
              dispatch(setIsEmptyDueTomorrowTask(false));
            }
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getDueFutureTask = (payload) => {
  return (dispatch) => {
    try {
      const { userId, callback, query = {} } = payload;
      new APIRequest()
        .get(FEED_TASK, {
          assigned_to: userId,
          future: moment().add(1, 'days').format('YYYY-MM-DD'),
          ...query
        })
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setDueFutureTask(resp.data));
            if (isEmpty(resp.data)) {
              dispatch(setIsEmptyDueFutureTask(true));
            } else {
              dispatch(setIsEmptyDueFutureTask(false));
            }
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateFeedTaskStatus = (payload, successCb) => {
  return (dispatch) => {
    try {
      new APIRequest()
        .post(LEAD_UPDATE_TASK_STATUS, payload)
        .then((res) => {
          if (successCb) {
            successCb(res);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateFeedTask = (payload) => {
  return (dispatch) => {
    const { taskId, data, callback } = payload;
    try {
      new APIRequest()
        .put(replaceUrl(LEAD_UPDATE_TASK, 'taskId', taskId), data)
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback();
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default feedTaskSlice.reducer;
