const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// api to connect whatsapp channel
export const CONNECT_WHATSAPP_CHANNEL = `${BASE_API_URL}/whatsapps/servers/create_whatsapp_info`;

// api to updated status for last message recieved
export const UPDATE_LAST_REPLY_RECIEVED_STATUS = `${BASE_API_URL}/whatsapps/servers/marking_message_as_read`;

// api to send whatsapp reply
export const SEND_WHATSAPP_REPLY = `${BASE_API_URL}/whatsapps/servers/send_whatsapp_message`;

// api to send media whatsapp reply
export const SEND_WHATSAPP_MEDIA_REPLY = `${BASE_API_URL}/whatsapps/servers/send_whatsapp_media_message/sub_org/:subOrgId/lead/:leadId`;

// api to fetch whatsapp templates
export const GET_WHATSAPP_DEFAULT_TEMPLATE = `${BASE_API_URL}/whatsapps/servers/get_message_templates`;

// api to send default whatsapp templates
export const SEND_WHATSAPP_DEFAULT_TEMPLATE = `${BASE_API_URL}/whatsapps/servers/send_whatsapp_template`;
