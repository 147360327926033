/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  GET_ALL_CURRENCIES,
  GET_BACKGROUNG_COLOR,
  GET_BUSINESS_INFOS,
  GET_COUNTRY_LIST,
  ORGANIZATION_PLAN_SIGNUP,
  UPDATED_BUSINESS_INFO,
  GET_ORGANIZATION_PHONE_PREFIX
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import { isEmpty } from 'utils/utils';

export const businessInfoSettings = createSlice({
  name: 'businessInfoSettings',
  initialState: {
    currenciesData: [],
    businessInfoData: [],
    backgroundColor: '',
    countryList: [],
    stripeCheckoutUrl: '',
    loadingFlag: false
  },
  reducers: {
    setCurrenciesData: (state, action) => {
      state.currenciesData = action.payload;
    },
    setBusinessInfoData: (state, action) => {
      state.businessInfoData = action.payload;
    },
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
    setLoadingFlag: (state, action) => {
      state.loadingFlag = action.payload;
    },
    setStripeCheckoutUrl: (state, action) => {
      state.stripeCheckoutUrl = action.payload;
    }
  }
});

export const {
  setCurrenciesData,
  setBusinessInfoData,
  setBackgroundColor,
  setCountryList,
  setLoadingFlag,
  setStripeCheckoutUrl
} = businessInfoSettings.actions;

export const getBusinessInfoData = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { subOrgId } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_BUSINESS_INFOS, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBusinessInfoData(res?.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateBusinessInfoData = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    try {
      const { subOrgId, data } = payload;
      await new APIRequest()
        .patch(replaceUrl(UPDATED_BUSINESS_INFO, 'subOrgId', subOrgId), data)
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess();
            }
          }
        });
    } catch (e) {
      if (onError) {
        onError();
      }
    }
  };
};

export const getCurrenciesData = () => {
  return async (dispatch) => {
    try {
      await new APIRequest().get(GET_ALL_CURRENCIES).then((res) => {
        if (res.status === 200) {
          dispatch(setCurrenciesData(res?.data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getBackgroundColor = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { subOrgId } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_BACKGROUNG_COLOR, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback();
            }
            dispatch(setBackgroundColor(res?.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default businessInfoSettings.reducer;

export const getCountryList = (payload) => {
  return async (dispatch) => {
    try {
      return new APIRequest()
        .get(replaceUrl(GET_COUNTRY_LIST))
        .then((resp) => {
          if (resp.status === 200) {
            if (!isEmpty(resp.data)) {
              dispatch(setCountryList(resp.data));
            } else {
              dispatch(setCountryList([]));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const getOrganizationPhonePrefix = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { subOrgId } = payload;
      return new APIRequest()
        .get(replaceUrl(GET_ORGANIZATION_PHONE_PREFIX, 'subOrgId', subOrgId))
        .then((resp) => {
          if (resp.status === 200) {
            if (callback) {
              callback(resp.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log('e', e);
    }
  };
};

export const organizationPlanSignup = (payload, subOrgId, successCb) => {
  return async () => {
    try {
      new APIRequest()
        .post(
          replaceUrl(ORGANIZATION_PLAN_SIGNUP, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            successCb(res.data);
          }
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };
};
