import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_URL,
  SIGN_UP_RTK,
  VERIFY_USER_EMAIL_RTK,
  LOG_IN_RTK,
  USER_SOCIAL_SIGNUP_RTK,
  INVITED_USER_SIGNUP_RTK,
  RESEND_CONFIRMATION_EMAIL_RTK,
  FORGOT_PASSWORD_RTK,
  CREATE_PASSWORD,
  VERIFY_FORGOT_PASSWORD
} from 'urls';
import { ApiHeaders } from '../headerSetup';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: ApiHeaders
  }),
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (body) => {
        return {
          url: SIGN_UP_RTK,
          method: 'post',
          body
        };
      }
    }),
    verifyEmail: builder.mutation({
      query: (body) => {
        return {
          url: VERIFY_USER_EMAIL_RTK,
          method: 'post',
          body
        };
      }
    }),
    userLogin: builder.mutation({
      query: (body) => {
        return {
          url: LOG_IN_RTK,
          method: 'post',
          body
        };
      }
    }),
    socialSign: builder.mutation({
      query: (body) => {
        return {
          url: USER_SOCIAL_SIGNUP_RTK,
          method: 'post',
          body
        };
      }
    }),
    signUpInviteUser: builder.mutation({
      query: (body) => {
        return {
          url: INVITED_USER_SIGNUP_RTK,
          method: 'post',
          body
        };
      }
    }),
    resendLink: builder.mutation({
      query: (body) => {
        return {
          url: RESEND_CONFIRMATION_EMAIL_RTK,
          method: 'post',
          body
        };
      }
    }),
    forgotPassword: builder.mutation({
      query: (body) => {
        return {
          url: FORGOT_PASSWORD_RTK,
          method: 'post',
          body
        };
      }
    }),
    createPassword: builder.mutation({
      query: ({ token, body }) => {
        return {
          url: CREATE_PASSWORD,
          method: 'put',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body
        };
      }
    }),
    verifyForgotPassword: builder.mutation({
      query: (body) => {
        return {
          url: VERIFY_FORGOT_PASSWORD,
          method: 'post',
          body
        };
      }
    })
  })
});

export const {
  useSignUpMutation,
  useVerifyEmailMutation,
  useUserLoginMutation,
  useSocialSignMutation,
  useSignUpInviteUserMutation,
  useResendLinkMutation,
  useForgotPasswordMutation,
  useCreatePasswordMutation,
  useVerifyForgotPasswordMutation
} = authApi;
