/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from 'react';
const App = lazy(() => import('./App'));
import LogoLoader from 'components/logoLoader';

const Main = () => {
  return (
    <Suspense fallback={<LogoLoader />}>
      <App />
    </Suspense>
  );
};

export default Main;
