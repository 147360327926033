const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// Chatbot website
export const CONNECT_WEBSITE_CHATBOT = `${BASE_API_URL}/website_chat_bots/sub_organizations/:subOrgId/channels/:channelId/connect_channel`;

export const GET_CHATBOT_DETAILS = `${BASE_API_URL}/website_chat_bots/sub_organizations/:subOrgId/connected_channels/:channelId`;

export const CREATE_OPENING_MESSAGES = `${BASE_API_URL}/website_chat_bots/sub_organizations/:subOrgId/opening_messages`;

export const UPDATE_OPENING_MESSAGES = `${BASE_API_URL}/website_chat_bots/sub_organizations/:subOrgId/opening_messages/:openingMessageId`;

export const UPDATE_OPTIONS = `${BASE_API_URL}/website_chat_bots/sub_organizations/:subOrgId/mcqs/:mcqOptionId`;
