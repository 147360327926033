/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { WHOEVER__IS_AVAILABLE } from 'utils/constants';
import {
  GET_LIST_ENQUIRIES_WITH_TASKS,
  GET_ENQUIRY_TASK_COUNT,
  MARK_ALL_OVERDUE_TASK_AS_COMPLETED
} from 'urls';
import { MARK_COMPLETE_DEFAULT_TASK } from 'urls/tasks';
import { updateInitialTasksSuborganization } from 'slices/myInfoSlice';
import CHOICES from 'choices';
import { getDateInTimezone, isDateAndTimeOverdue } from 'utils/dateFormat';
import { setAllCompletedTasks, setAllNewTasks } from 'slices/task/slice';

const { OVERDUE, DUE_TODAY, NEXT_7_DAYS, BEYOND_7_DAYS } =
  CHOICES.TaskLeadApiFilterChoices;
const initialState = {
  things_to_do_count: {},
  task_due_today: {},
  task_overdue: {},
  task_next_7days: {},
  task_beyond_7days: {},
  activeTabforTasks: DUE_TODAY,
  active_default_task: null,
  activeLeadIdforTask: null
};

export const taskThingsToDo = createSlice({
  name: 'taskThingsToDo',
  initialState,
  reducers: {
    setTaskDueToday: (state, action) => {
      state.task_due_today = action.payload;
    },
    setTaskOverdue: (state, action) => {
      state.task_overdue = action.payload;
    },
    setTaskNextSevenDays: (state, action) => {
      state.task_next_7days = action.payload;
    },
    setTaskBeyondSevenDays: (state, action) => {
      state.task_beyond_7days = action.payload;
    },
    setThingsToDoCount: (state, action) => {
      state.things_to_do_count = action.payload;
    },
    setActiveDefaultTask: (state, action) => {
      state.active_default_task = action.payload;
    },
    setActiveTabforTasks: (state, action) => {
      state.activeTabforTasks = action.payload;
    },
    setActiveLeadIdforTasks: (state, action) => {
      state.activeLeadIdforTask = action.payload;
    }
  }
});

export const {
  setTaskDueToday,
  setTaskOverdue,
  setThingsToDoCount,
  setActiveDefaultTask,
  setTaskBeyondSevenDays,
  setTaskNextSevenDays,
  setActiveTabforTasks,
  setActiveLeadIdforTasks
} = taskThingsToDo.actions;

export const getAllTasks = (payload) => {
  return async (dispatch, getState) => {
    const { activeTabforTasks } = getState().taskThingsToDo;
    switch (activeTabforTasks) {
      case DUE_TODAY:
        dispatch(getTasksDueToday(payload));
        break;
      case NEXT_7_DAYS:
        dispatch(getTasksNext7Days(payload));
        break;
      case BEYOND_7_DAYS:
        dispatch(getTasksBeyond7Days(payload));
        break;
      default:
        dispatch(getTasksOverdue(payload));
        break;
    }
  };
};

export const getTasksDueToday = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      task_status: DUE_TODAY,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callBack, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTaskDueToday(res.data.data));
            if (callBack) callBack(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (callBack) callBack();
        });
    } catch (e) {
      if (callBack) callBack();
      console.log(e);
    }
  };
};

export const getTasksNext7Days = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      task_status: NEXT_7_DAYS,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callBack, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTaskNextSevenDays(res.data.data));
            if (callBack) callBack(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (callBack) callBack();
        });
    } catch (e) {
      console.log(e);
      if (callBack) callBack();
    }
  };
};

export const getTasksBeyond7Days = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      task_status: BEYOND_7_DAYS,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callBack, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTaskBeyondSevenDays(res.data.data));
            if (callBack) callBack(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (callBack) callBack();
        });
    } catch (e) {
      if (callBack) callBack();
      console.log(e);
    }
  };
};

export const getTasksOverdue = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      task_status: OVERDUE,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callBack, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTaskOverdue(res.data.data));
            if (callBack) callBack(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (callBack) callBack();
        });
    } catch (e) {
      if (callBack) callBack();
      console.log(e);
    }
  };
};

export const getTaskEnquiryCount = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    const params = {
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callback, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_ENQUIRY_TASK_COUNT, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              setThingsToDoCount({
                ...things_to_do_count,
                ...res.data
              })
            );
            //   if (callback) {
            //     callback();
            //   }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const markCompleteDefaultTask = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { taskKey, successCb, errorCb } = payload;
    const params = {
      task: taskKey
    };
    try {
      return await new APIRequest()
        .post(replaceUrl(MARK_COMPLETE_DEFAULT_TASK, 'subOrgId', subOrgId), {
          ...params
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              updateInitialTasksSuborganization(
                res?.data?.initial_tasks_suborganization
              )
            );
            if (successCb) {
              successCb();
            }
          } else {
            if (errorCb) {
              errorCb();
            }
          }
        });
    } catch (e) {
      console.log('e', e);
    }
  };
};

export const markAllOverdueTaskAsCompleted = (succesCb, failedCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      return await new APIRequest()
        .post(
          replaceUrl(MARK_ALL_OVERDUE_TASK_AS_COMPLETED, 'subOrgId', subOrgId)
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTaskOverdue({}));
            if (succesCb) succesCb();
          }
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};
export const decreaseThingsToDoCount = () => {
  return async (dispatch, getState) => {
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    const enquiry_count = things_to_do_count.enquiry_count;
    dispatch(
      setThingsToDoCount({
        ...things_to_do_count,
        enquiry_count: enquiry_count > 0 ? enquiry_count - 1 : 0
      })
    );
  };
};

export const increaseThingsToDoCount = () => {
  return async (dispatch, getState) => {
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    const enquiry_count = things_to_do_count.enquiry_count;
    dispatch(
      setThingsToDoCount({
        ...things_to_do_count,
        enquiry_count: enquiry_count + 1
      })
    );
  };
};

export const updateThingsToDoCount = (payload) => {
  return async (dispatch, getState) => {
    const things_to_do_count = getState().taskThingsToDo.things_to_do_count;
    dispatch(
      setThingsToDoCount({
        ...things_to_do_count,
        enquiry_count: payload
      })
    );
  };
};

export default taskThingsToDo.reducer;
