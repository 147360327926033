import { createSlice } from '@reduxjs/toolkit';
import { IP_DATA_URL } from 'urls/signup';
// import { USER_SOCIAL_SIGNUP } from 'urls';
// import APIRequest from 'apiRequest';

// to test with uk ip
// const IPDATACONST = {
//   ip: '103.171.98.18',
//   country_code: 'GB',
//   time_zone: {
//     name: 'Europe/London',
//     abbr: 'IST',
//     offset: '+0530',
//     is_dst: false,
//     current_time: '2024-03-22T04:59:08+05:30'
//   }
// };
const initialState = {
  authToken: null,
  signUpFirstName: '',
  signUpSubOrgId: null,
  signUpAuthToken: null,
  ipData: {},
  currentUser: null,
  crmLoading: false,
  email: '',
  signUpChoice: 0,
  error: null,
  redirect: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setSignUpAuthToken: (state, action) => {
      state.signUpAuthToken = action.payload;
    },
    clearAuthToken: (state) => {
      state.authToken = null;
      state.signUpAuthToken = null;
      state.signUpFirstName = '';
      state.signUpSubOrgId = null;
      state.currentUser = null;
      state.email = '';
      state.signUpChoice = 0;
      state.error = null;
      state.redirect = null;
      state.ipData = {};
      state.crmLoading = false;
    },
    setIpData: (state, action) => {
      state.ipData = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setSignUpChoice: (state, action) => {
      state.signUpChoice = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload;
    },
    setSignUpSubOrgId: (state, action) => {
      state.signUpSubOrgId = action.payload;
    },
    setSignUpFirstName: (state, action) => {
      state.signUpFirstName = action.payload;
    },
    setCrmLoading: (state, action) => {
      state.crmLoading = action.payload;
    }
  }
});

export const {
  setAuthToken,
  setSignUpAuthToken,
  clearAuthToken,
  setCurrentUser,
  setError,
  setEmail,
  setSignUpChoice,
  setRedirect,
  setIpData,
  setSignUpSubOrgId,
  setSignUpFirstName,
  setCrmLoading
} = authSlice.actions;

export const getIpData = async (callback) => {
  await fetch(IP_DATA_URL)
    .then((res) => res.json())
    .then((data) => {
      if (callback) callback(data);
    });
};
// export const createSocialSignUp = ({ payload, callback }) => {
//   return async (dispatch) => {
//     try {
//       const { authIdToken, whichSocialAuth, is_this_signup_request } = payload;
//       new APIRequest()
//         .post(USER_SOCIAL_SIGNUP, {
//           auth_id_token: authIdToken,
//           which_social_auth: whichSocialAuth,
//           is_this_signup_request
//         })
//         .then((res) => {
//           if (res.status === 200) {
//             if (res.data.frontend_redirect_uri) {
//               if (callback) {
//                 dispatch(setRedirect(res.data.frontend_redirect_uri));
//                 setTimeout(() => {
//                   callback(res.data.frontend_redirect_uri);
//                 }, 500);
//               }
//             }
//             if (res?.data?.token) {
//               dispatch(setAuthToken(res?.data?.token));
//             }
//           }
//         })
//         .catch((error) => {
//           if (error?.response?.data) {
//             dispatch(setError(error?.response?.data.message));
//           }
//           if (error?.response?.data?.description === 'You can try login.') {
//             const payload = {
//               authIdToken,
//               whichSocialAuth,
//               is_this_signup_request: false
//             };
//             dispatch(
//               createSocialSignIn({ payload: payload, callback: callback })
//             );
//           }
//         });
//     } catch (e) {
//       return '';
//     }
//   };
// };

// export const createSocialSignIn = ({ payload, callback }) => {
//   return async (dispatch) => {
//     try {
//       const { authIdToken, whichSocialAuth, is_this_signup_request } = payload;
//       new APIRequest()
//         .post(USER_SOCIAL_SIGNUP, {
//           auth_id_token: authIdToken,
//           which_social_auth: whichSocialAuth,
//           is_this_signup_request
//         })
//         .then((res) => {
//           if (res.status === 200) {
//             if (res.data.frontend_redirect_uri) {
//               if (callback) {
//                 dispatch(setRedirect(res.data.frontend_redirect_uri));
//                 setTimeout(() => {
//                   callback(res.data.frontend_redirect_uri);
//                 }, 500);
//               }
//             }
//             if (res?.data?.token) {
//               dispatch(setAuthToken(res?.data?.token));
//             }
//           }
//         })
//         .catch((err) => console.log(err));
//       // const { authIdToken, whichSocialAuth } = payload;
//       // mutate({
//       //   mutation: SocialSignIn,
//       //   input: {
//       //     authIdToken,
//       //     whichSocialAuth
//       //   },
//       //   onSuccess: function (resp) {
//       //     console.log({ resp });
//       //     // const __typename = resp.socialSignIn.response.__typename;
//       //     // const redirectUri = resp.socialSignIn.response.redirectUri
//       //     const { __typename, redirectUri, token, userId } = resp.socialSignIn.response;
//       //     if (__typename === 'UserDoesNotExists') {
//       //       dispatch(setError(resp.socialSignIn.response.error.title));
//       //     }
//       //     if (__typename === 'SuccessSignIn') {
//       //       // if (token) {
//       //       //   dispatch(setAuthToken(token));
//       //       // }
//       //       // dispatch(setCurrentUser(userId))
//       //       // console.log(typeof redirectUri, typeof BusinessInfo, typeof Dashboard)
//       //       if (parseInt(redirectUri) === BusinessInfo) {
//       //         dispatch(setCurrentUser(userId));
//       //         callback('/business-info');
//       //       }
//       //       if (parseInt(redirectUri) === Dashboard) {
//       //         dispatch(setAuthToken(token));
//       //         callback('/dashboard');
//       //       }
//       //     }
//       //   },
//       //   onErrors: (error) => {
//       //     let error_message = error.map((item) => item.message);
//       //     dispatch(setError(error_message.toString()));
//       //   }
//       // });
//     } catch (e) {
//       return '';
//     }
//   };
// };

// export const createUser = (payload, callback) => {
//   return async (dispatch) => {
//     try {
//       const { name, password, email } = payload;
//       const formData = {
//         first_name: name,
//         email: email,
//         password: password
//       };
//       new APIRequest()
//         .post(SIGN_UP, formData, {}, { withNoToken: true })
//         .then((resp) => {
//           if (resp.status === 201) {
//             if (resp?.data?.message === 'confirmation mail sent') {
//               dispatch(setEmail(email));
//               dispatch(setCurrentUser(resp?.data?.user_id));
//               callback(email);
//             }
//           }
//         })
//         .catch((err) => {
//           dispatch(setError(err.response.data.email));
//           dispatch(setEmail(''));
//         });
//     } catch (e) {
//       console.log(' e', e);
//       dispatch(setError('something went wrong'));
//       dispatch(setEmail(''));
//       return '';
//     }
//   };
// };

// export const loginUser = (payload, callback) => {
//   return async (dispatch) => {
//     try {
//       const { username, password } = payload;
//       const formData = {
//         username: username,
//         password: password
//       };
//       new APIRequest()
//         .post(LOG_IN, formData, {}, { withNoToken: true })
//         .then((resp) => {
//           if (resp.status === 200) {
//             dispatch(setAuthToken(resp?.data?.access));
//             dispatch(setRedirect(resp?.data?.frontend_redirect_uri));
//             callback();
//           }
//         })
//         .catch((err) => {
//           dispatch(setError(err.response.data.detail));
//         });
//     } catch (e) {
//       console.log(' e', e);
//       dispatch(setError('something went wrong'));
//       return '';
//     }
//   };
// };

// export const createBusiness = (payload, callback) => {
//   return async (dispatch) => {
//     try {
//       const { website_link, industry_type, team_size, business_name } = payload;
//       const formData = {
//         industry_type: industry_type,
//         website_link: website_link,
//         team_size: team_size,
//         organization_name: business_name
//       };
//       new APIRequest()
//         .post(CREATE_BUSINESS_INFO, formData)
//         .then((resp) => {
//           console.log(resp);
//           callback();
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     } catch (e) {
//       console.log(' e', e);
//       dispatch(setError('something went wrong'));
//       return '';
//     }
//   };
// return async (dispatch) => {
//   try {
//     const { name, websiteLink, industry, teamSize, userId } = payload;
//     mutate({
//       mutation: CreateBusinessMutation,
//       input: {
//         name,
//         websiteLink,
//         industry,
//         teamSize,
//         userId
//       },
//       onSuccess: function ({ createBusiness: { business, redirectUri, token } }) {
//         console.log({
//           business
//         });
//         if (token) {
//           dispatch(setAuthToken(token));
//         }
//         if (callback) {
//           callback(redirectUri);
//         }
//       },
//       onErrors: () => {
//         console.log(' Mutate error! ');
//       }
//     });
//   } catch (e) {
//     return '';
//   }
// };
// };

export const createPassword = (payload, successCb, errorCb) => {
  // return async (dispatch) => {
  //   try {
  //     const { key, userId, password1, password2 } = payload;
  //     mutate({
  //       mutation: CreatePasswordMutation,
  //       input: {
  //         key,
  //         userId,
  //         password1,
  //         password2
  //       },
  //       onSuccess: function ({ setUserNewPassword: { redirectUri, result, token } }) {
  //         if (token) {
  //           dispatch(setAuthToken(token));
  //         }
  //         if (successCb) {
  //           successCb(result, redirectUri);
  //         }
  //       },
  //       onErrors: (error) => {
  //         errorCb(error);
  //         console.log(' Mutate error! ');
  //       }
  //     });
  //   } catch (e) {
  //     return '';
  //   }
  // };
};

export const forgotPassword = (payload, successCb, errorCb) => {
  // return async (dispatch) => {
  //   try {
  //     const { email } = payload;
  //     mutate({
  //       mutation: ForgotPasswordMutation,
  //       input: {
  //         emailAddress: email
  //       },
  //       onSuccess: function ({ forgotPassword: { response } }) {
  //         console.log({ response });
  //         // if (token) {
  //         //   dispatch(setAuthToken(token));
  //         // }
  //         if (successCb) {
  //           successCb(response);
  //         }
  //       },
  //       onErrors: (error) => {
  //         if (errorCb) {
  //           errorCb(error);
  //         }
  //         console.log(' Mutate error! ');
  //       }
  //     });
  //   } catch (e) {
  //     return '';
  //   }
  // };
};

// export const createAccount = (payload, callback) => {
//   return async (dispatch) => {
//     try {
//       const formData = payload;
//       new APIRequest()
//         .post(INVITED_USER_SIGNUP, formData)
//         .then((res) => {
//           if (res.status === 200) {
//             dispatch(setError(res.data.message));
//             if (callback) {
//               callback(res.data);
//             }
//           }
//         })
//         .catch((err) => {
//           dispatch(setError(err.response.data.email));
//           console.log(err);
//         });
//     } catch (e) {
//       console.log('e', e);
//       dispatch(setError('something went wrong'));
//       return '';
//     }
//   };
// };

export default authSlice.reducer;
