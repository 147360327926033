import { createSlice } from '@reduxjs/toolkit';
import { GET_ALL_LEAD_COUNT, MARK_NOTIFICATION_AS_READ } from 'urls';
import APIRequest from 'apiRequest';

export const allCount = createSlice({
  name: 'allCount',
  initialState: {
    incoming_count: 0,
    box_count: {},
    users_count: {}
  },
  reducers: {
    setIncomingCount: (state, action) => {
      state.incoming_count = action.payload;
    },
    setBoxCount: (state, action) => {
      state.box_count = action.payload;
    },
    setUserCount: (state, action) => {
      state.users_count = action.payload;
    }
  }
});

export const { setIncomingCount, setBoxCount, setUserCount } = allCount.actions;

export const getAllCounts = (payload) => {
  return (dispatch, getState) => {
    const { subOrganizationId } = getState().myInfo;
    try {
      const query = {
        sub_organization_id: subOrganizationId
      };
      new APIRequest().get(GET_ALL_LEAD_COUNT, query).then((res) => {
        if (res.status === 200) {
          dispatch(setIncomingCount(res.data.incoming_lead));
          dispatch(setBoxCount(res.data.box));
          // dispatch(setUserCount(res.data.user));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const markNotificationAsRead = (payload) => {
  return (dispatch, getState) => {
    try {
      const { body } = payload;
      new APIRequest().post(MARK_NOTIFICATION_AS_READ, body).then((res) => {
        if (res.status === 200) {
          dispatch(getAllCounts());
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const incrementBoxCount = ({ box_id }) => {
  return (dispatch, getState) => {
    const boxes = getState().allCount.box_count;
    const count = boxes[box_id] || 0;
    const boxes_copy = { ...boxes };
    boxes_copy[box_id] = count + 1;
    dispatch(setBoxCount(boxes_copy));
  };
};

export const decrementBoxCount = ({ box_id }) => {
  return (dispatch, getState) => {
    const boxes = getState().allCount.box_count;
    const count = boxes[box_id];
    const boxes_copy = { ...boxes };
    boxes_copy[box_id] = count > 0 ? count - 1 : 0;
    dispatch(setBoxCount(boxes_copy));
  };
};

export default allCount.reducer;
