import { createSlice } from '@reduxjs/toolkit';
// import { getAllPendingChannels } from 'slices/channelsSlice';
import { GET_CONNECTED_WEBFORM_API } from 'urls';
import { replaceUrl } from 'utils/urlReplace';
// import { getAllConnectedChannels } from './channelsSlice';
import APIRequest from 'apiRequest';

export const webformSlice = createSlice({
  name: 'webforms',
  initialState: {
    webformsScript: null
  },
  reducers: {
    setWebformsData: (state, action) => {
      state.webformsScript = action.payload;
    }
  }
});

export const { setWebformsData } = webformSlice.actions;

export default webformSlice.reducer;

export const getConnectedWebform = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            GET_CONNECTED_WEBFORM_API,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateWebFormData = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId, body } = payload;
      new APIRequest()
        .patch(
          replaceUrl(
            GET_CONNECTED_WEBFORM_API,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          ),
          body
        )
        .then((res) => {
          if (res.status === 200) {
            // dispatch(getAllConnectedChannels({ subOrgId }));
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          if (errorCb) {
            errorCb(err);
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};
