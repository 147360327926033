const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// to get the report data
export const GET_ALL_REPORT_DATA = `${BASE_API_URL}/reports/get_reports/:subOrgId`;

// to get box report
export const GET_ALL_BOX_REPORT = `${BASE_API_URL}/reports/get_box_report/:boxId`;

// to get team report
export const GET_ALL_TEAM_REPORT = `${BASE_API_URL}/reports/get_team_report/:subOrgId`;

// get all box enquiry report
export const GET_ALL_BOX_ENQUIRY_REPORT = `${BASE_API_URL}/reports/enquiry-reports/:subOrgId`;

// get all box conversion report
export const GET_ALL_BOX_CONVERSION_REPORT = `${BASE_API_URL}/reports/conversion-reports/:subOrgId`;

// get individual box enquiry report
export const GET_BOX_ENQUIRY_REPORT = `${BASE_API_URL}/reports/box-enquiry-reports/:boxId`;

// get individual box conversion report
export const GET_BOX_CONVERSION_REPORT = `${BASE_API_URL}/reports/box-conversion-reports/:boxId`;

// get individual box stage report
export const GET_BOX_STAGE_REPORT = `${BASE_API_URL}/reports/box-stages-reports/:boxId`;
