const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const GET_MY_BOX_PROVIDED_EMAIL = `${BASE_API_URL}/emails/get_my_enquiry_box_provided_email_address`;
export const GET_EMAIL_VERIFICATION_PROVIDERS = `${BASE_API_URL}/emails/get_email_verification_providers`;
export const SEND_EMAIL_VERIFICATION = `${BASE_API_URL}/emails/verify_my_email_address_using_ses`;
export const UPDATE_SENDER_NAME = `${BASE_API_URL}/emails/update_sender_name_in_from_mail_address`;
export const SWITCH_EMAIL = `${BASE_API_URL}/emails/switch_my_sending_replies_default_provider`;
export const CREATE_CONNECTED_CHANNEL = `${BASE_API_URL}/channels/sub_organizations/:subOrgId/create_connected_channel`;
export const ADD_EMAIL_ADDRESS = `${BASE_API_URL}/email_forwardings/connected_channels/:channelId/email_forwardings`;
export const SEND_BLOCKED_ALLOWED_FORWARDED_EMAIL = `${BASE_API_URL}/email_forwardings/connected_channels/:channelId/email_forwardings/:forwardedEmailId`;
