import { createSlice } from '@reduxjs/toolkit';
import {
  GET_DOMAIN_DETAILS,
  DELETE_DOMAIN,
  CREATE_DOMAIN
} from 'urls/domainVerification';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';

export const domainVerificationSlice = createSlice({
  name: 'domainVerification',
  initialState: {
    domainDetails: null
  },
  reducers: {
    setDomains: (state, action) => {
      state.domainDetails = action.payload;
    }
  }
});

export const { setDomains } = domainVerificationSlice.actions;

export const createDomain = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest().post(CREATE_DOMAIN, payload).then((res) => {
        if (successCb) {
          successCb(res.data);
        }
      });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb(e.response);
      }
    }
  };
};

export const getDomainDetails = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      const query = {
        sub_org_id: payload
      };
      await new APIRequest().get(GET_DOMAIN_DETAILS, query).then((res) => {
        if (
          res?.data?.ErrorCode == '510' &&
          res?.status == '200' &&
          res.data.Message == 'This domain was not found.'
        ) {
          errorCb(res?.data.Messsage);
          dispatch(setDomains({}));
        } else {
          dispatch(setDomains(res?.data));
          if (successCb) {
            successCb(res?.data);
          }
        }
      });
    } catch (e) {
      console.log('e', e);
      dispatch(setDomains(null));
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const deleteDomain = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .delete(replaceUrl(DELETE_DOMAIN, 'subOrgId', payload))
        .then((res) => {
          if (res.status == 200) {
            dispatch(setDomains({}));
            if (successCb) {
              successCb();
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export default domainVerificationSlice.reducer;
