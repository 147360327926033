import { combineReducers } from '@reduxjs/toolkit';
import { authApi } from 'services/authApi';
import { organizationApi } from 'services/organizationApi';
import { socialCrendetialsApi } from 'services/socialApi';
import { myInfoApi } from 'services/myInfoApi';
import appReducer from '../slices/appSlice';
import authReducer from '../slices/authSlice';
import socialCredentialsReducer from '../slices/socialCredentialSlice';
import myInfoReducer from '../slices/myInfoSlice';
import channelsReducer from '../slices/channelsSlice';
import webformsReducer from '../slices/webformSlice';
import eboxLeadsReducer from '../slices/eboxLead';
import historyLeadReducer from '../slices/historyLeadSlice';
import webformMappingReducer from '../slices/webformMappingSlice';
import leadConversationReducer from '../slices/conversationSlice';
import snackbarReducer from '../slices/snackbarSlice';
import leadsFilterReducer from '../slices/leadsFilter';
import labelsReducer from '../slices/labelsSlice';
import organizationuserReducer from '../slices/organizationuserSlice';
import teamChatReducer from '../slices/teamChatSlice';
import userNotificationsReducer from '../slices/userNotificationSlice';
import questionsReducer from '../slices/questionSlice';
import notesReducer from '../slices/noteSlice';
import automationStuff from 'slices/automationStuffSlice';
import facebookReducer from '../slices/facebook/slice';
import userProfileReducer from '../slices/userProfileSlice';
import taskReducer from '../slices/task/slice';
import fileLibraryReducer from '../slices/fileLibrary/slice';
import feedTaskReducer from '../slices/feedTask/slice';
import editorSlice from '../slices/editor/slice';
import boxesReducer from '../slices/boxes/slice';
import cardDesignSettingsReducer from 'slices/cardDesignSettingsSlice';
import businessInfoSettingsReducer from 'slices/businessInfoSlice';
import uploadContactsSettingsReducer from 'slices/boxSettings/uploadContactsSlice';
import enquiryBinReducer from 'slices/enquiryBin/slice';
import boxAutomationsReducer from 'slices/boxAutomation/slice';
import customBoxSliceReducer from 'slices/customBoxSlice';
import replyTemplateReducer from 'slices/replyTemplate/slice';
import emailAddressReducer from 'slices/emailAddress/slice';
import reportsReducer from 'slices/reports/slice';
import smsReducer from 'slices/sms/slice';
import allCountReducer from 'slices/allCount/slice';
import segmentsReducer from 'slices/segments/slice';
import boxesNewEnquiresReducer from 'slices/boxesNewEnquiresSlice';
import allNotesReducer from 'slices/notes/slice';
import subscriptionReducer from 'slices/subscription/slice';
import taskThingsToDoReducer from 'slices/taskThingsToDo/slice';
import socketReducer from 'slices/sockets/slice';
import whatsAppReducer from 'slices/whatsApp/slice';
import chatbotReducer from 'slices/chatbot/slice';
import domainVerificationReducer from 'slices/domainVerification/slice';
import senderEmailReducer from 'slices/senderEmails/slice';
import crmBuilderReducer from 'slices/CRM/slice';
import confettiReducer from 'slices/confetti/slice';
import WebformForwardingReducer from 'slices/webformForwarding/slice';
import locationLabelReducer from 'slices/locationLabelSlice';

export const whitelist = ['auth'];

export const getMiddleWare = (getDefaultMiddleware) => {
  return getDefaultMiddleware({
    serializableCheck: false
  }).concat([
    authApi.middleware,
    organizationApi.middleware,
    socialCrendetialsApi.middleware,
    myInfoApi.middleware
  ]);
};

const ApiReducers = () => {
  const reducers = {
    [authApi.reducerPath]: authApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [socialCrendetialsApi.reducerPath]: socialCrendetialsApi.reducer,
    [myInfoApi.reducerPath]: myInfoApi.reducer
  };
  return reducers;
};

const storeReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  social: socialCredentialsReducer,
  myInfo: myInfoReducer,
  channels: channelsReducer,
  snackbar: snackbarReducer,
  webforms: webformsReducer,
  leads: eboxLeadsReducer,
  historyLead: historyLeadReducer,
  organizationUser: organizationuserReducer,
  mapFields: webformMappingReducer,
  leadConversation: leadConversationReducer,
  labels: labelsReducer,
  leadsFilter: leadsFilterReducer,
  teamChat: teamChatReducer,
  usersNotification: userNotificationsReducer,
  question: questionsReducer,
  notes: notesReducer,
  automationStuff: automationStuff,
  fb: facebookReducer,
  userProfile: userProfileReducer,
  tasks: taskReducer,
  fileLibrary: fileLibraryReducer,
  feedTask: feedTaskReducer,
  editor: editorSlice,
  boxes: boxesReducer,
  cardDesignSettings: cardDesignSettingsReducer,
  businessInfoSettings: businessInfoSettingsReducer,
  uploadContactsSettings: uploadContactsSettingsReducer,
  enquiryBin: enquiryBinReducer,
  boxAutomation: boxAutomationsReducer,
  addcustomBox: customBoxSliceReducer,
  replyTemplate: replyTemplateReducer,
  emailAddress: emailAddressReducer,
  reports: reportsReducer,
  sms: smsReducer,
  allCount: allCountReducer,
  segments: segmentsReducer,
  boxesNewEnquires: boxesNewEnquiresReducer,
  allNotes: allNotesReducer,
  subscription: subscriptionReducer,
  taskThingsToDo: taskThingsToDoReducer,
  sockets: socketReducer,
  whatsApp: whatsAppReducer,
  chatbot: chatbotReducer,
  domainVerification: domainVerificationReducer,
  senderEmail: senderEmailReducer,
  crmBuilder: crmBuilderReducer,
  confetti: confettiReducer,
  webformForwarding: WebformForwardingReducer,
  locationLabels: locationLabelReducer,
  ...ApiReducers()
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/clearAuthToken') {
    state = undefined;
  }

  return storeReducer(state, action);
};

export default rootReducer;
