/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { GET_CONNECTED_CHANNEL_BY_ID } from 'urls';
import {
  CONNECT_WHATSAPP_CHANNEL,
  GET_WHATSAPP_DEFAULT_TEMPLATE,
  SEND_WHATSAPP_DEFAULT_TEMPLATE,
  SEND_WHATSAPP_REPLY,
  UPDATE_LAST_REPLY_RECIEVED_STATUS
} from 'urls/whatsapp';
import { replaceUrl } from 'utils/urlReplace';

export const whatsAppSlice = createSlice({
  name: 'whatsApp',
  initialState: {
    connectedChannelById: {},
    isShowErrorModal: false,
    defaultTemplates: []
  },
  reducers: {
    setConnectedChannelById: (state, action) => {
      state.connectedChannelById = action.payload;
    },
    setIsShowErrorModal: (state, action) => {
      state.isShowErrorModal = action.payload;
    },
    setDefaultTemplates: (state, action) => {
      state.defaultTemplates = action.payload;
    }
  }
});

export const {
  setConnectedChannelById,
  setIsShowErrorModal,
  setDefaultTemplates
} = whatsAppSlice.actions;

export default whatsAppSlice.reducer;

export const connectWhatsApp = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback, errorCallback } = payload;
      new APIRequest()
        .post(CONNECT_WHATSAPP_CHANNEL, body)
        .then((resp) => {
          if (callback) {
            callback(resp.data);
          }
        })
        .catch((err) => {
          errorCallback(err);
          dispatch(setIsShowErrorModal(true));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getConnectedChannelById = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId } = payload;
      await new APIRequest()
        .get(
          replaceUrl(
            GET_CONNECTED_CHANNEL_BY_ID,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setConnectedChannelById(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const updateReplyRecievedMessageStatus = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest()
        .post(UPDATE_LAST_REPLY_RECIEVED_STATUS, body)
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendWhatsAppReply = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest().post(SEND_WHATSAPP_REPLY, body).then((resp) => {
        if (callback) {
          callback();
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getWhatsAppDefaultTemplates = (payload) => {
  return async (dispatch) => {
    try {
      const { subOrgId, callback, errorCallback } = payload;
      const query = {
        sub_organization_id: subOrgId
      };
      new APIRequest()
        .get(GET_WHATSAPP_DEFAULT_TEMPLATE, query)
        .then((resp) => {
          // const approvedTemplates = resp?.data?.data.filter(
          //   (item) => item.status === 'APPROVED'
          // );
          dispatch(setDefaultTemplates(resp?.data?.message));
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          if (errorCallback) {
            errorCallback(err);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendWhatsAppDefaultTemplates = (payload) => {
  return async (dispatch) => {
    try {
      const { data, callback } = payload;
      new APIRequest()
        .post(SEND_WHATSAPP_DEFAULT_TEMPLATE, data)
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
