import { createSlice } from '@reduxjs/toolkit';

export const confettiSlice = createSlice({
  name: 'confetti',
  initialState: {
    showConfetti: false
  },
  reducers: {
    setShowConfetti: (state, action) => {
      state.showConfetti = action.payload;
    }
  }
});

export const { setShowConfetti } = confettiSlice.actions;

export default confettiSlice.reducer;
