const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// api to connect webform forwarding channel
export const CONNECT_WEBFORM_FORWARDING_CHANNEL = `${BASE_API_URL}/webform_forwarding/connect_webform_forwarding/:subOrgId`;

// api to get all the webform forwarding addresses
export const GET_ALL_WEBFORM_ADDRESS = `${BASE_API_URL}/webform_forwarding/get_list_of_new_webforms/:subOrgId`;

// api to create new webform address
export const CREATE_WEBFORM_ADDRESS = `${BASE_API_URL}/webform_forwarding/webform_crud`;

// api to delete webform address
export const DELETE_WEBFORM_ADDRESS = `${BASE_API_URL}/webform_forwarding/webform_crud/:addressId`;
