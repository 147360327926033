/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-useless-escape */
import moment from 'moment';
// import { scrollTo } from 'react-scroll/modules/mixins/scroller';
import { removeSignatureFromContent } from 'utils/dom';
import { getDateInTimezone } from './dateFormat';
import DOMPurify from 'dompurify';
// import { useLockBodyScroll } from "@uidotdev/usehooks";

export const isEmpty = (val) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
  test results
  --------------
  []        true, empty array
  {}        true, empty object
  null      true
  undefined true
  ""        true, empty string
  ''        true, empty string
  0         false, number
  true      false, boolean
  false     false, boolean
  Date      false
  function  false
  */
  if (val === undefined) return true;
  if (
    typeof val == 'function' ||
    typeof val == 'number' ||
    typeof val == 'boolean' ||
    Object.prototype.toString.call(val) === '[object Date]'
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == 'object') if (Object.keys(val).length === 0) return true;

  if (typeof val === 'string') if (val.trim() === '') return true;

  return false;
};

export const getHashParams = (hash) => {
  return hash.split('&').reduce(function (res, item) {
    var parts = item.split('=');
    res[parts[0].replace(/#/g, '')] = parts[1];
    return res;
  }, {});
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const TIME_ZONE = (-1 * new Date().getTimezoneOffset()) / 60;
export const timeFormatter = (givenDate, timezone, endDate) => {
  if (endDate) {
    return moment(givenDate).from(endDate, true);
  }
  if (timezone) givenDate = getDateInTimezone(givenDate, timezone);
  let date = new Date(givenDate).getDate();
  let monthName = months[new Date(givenDate).getMonth()];
  return moment(givenDate)
    .add(TIME_ZONE, 'mintues')
    .fromNow(
      moment.updateLocale('en', {
        relativeTime: {
          future: 'in%s',
          past: '%s',
          s: 'Now',
          m: '%dm',
          mm: '%dm',
          h: '%dh',
          hh: '%dh',
          d: '%dd',
          dd: '%dd',
          M: `${monthName} ${date}`,
          MM: `${monthName} ${date}`,
          y: '%d y',
          yy: '%d y'
        }
      })
    );
};

export const getKiloBytes = (bytes, decimals = 1) => {
  if (!+bytes) return '0';
  if (bytes <= 1024) return 1;
  const k = 1024;
  // const dm = decimals < 0 ? 0 : decimals;
  return Math.ceil(parseFloat(bytes / Math.pow(k, 1)));
};

export const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isPhoneValid = (value) => {
  var pattern = /^[\+\d]?(?:(?!.*--)[\d-\s]*)$/;
  return pattern.test(value);
};

export const isPhoneValidWithoutPlus = (value) => {
  var pattern = /^[\d]?(?:(?!.*--)[\d-\s]*)$/;
  return pattern.test(value);
};

export const isNumberWithDecimal = (value) => {
  var pattern = /^\d*\.?\d*$/;
  return pattern.test(value);
};

export const isEditorEmpty = (editor) => {
  return isEmpty(sanatizeMessageBody(removeSignatureFromContent(editor)));
  // return (
  //   isEmpty(editor) ||
  //   editor === '<div class="pre"><br></div>' ||
  //   editor === '<p><br><br></p>'
  // );
};

/**
 * Need to remove all spaces, new line characters and dom content from
 * the email body and the signature to check whether there are any content inside it
 */
export const isEditorOnlyContainsUserSignature = (
  emailContent,
  userSignature
) => {
  let cleanMailText = emailContent
    ? emailContent.split('\n').join('').trim()
    : '';
  cleanMailText = cleanMailText.split('&nbsp;').join('');
  cleanMailText = cleanMailText
    ? cleanMailText.replace(/(<([^>]+)>)/gi, '')
    : '';
  cleanMailText = cleanMailText.split(' ').join('').trim();
  let cleanUserSignature = userSignature
    ? userSignature.replace(/(<([^>]+)>)/gi, '')
    : '';
  cleanUserSignature = cleanUserSignature.split('\n').join('').trim();
  cleanUserSignature = cleanUserSignature.split(' ').join('').trim();
  cleanUserSignature = cleanUserSignature.split('&nbsp;').join('').trim();
  let isInvalid = false;
  if (
    cleanMailText.trim() === cleanUserSignature.trim() &&
    (cleanMailText || cleanUserSignature)
  ) {
    isInvalid = true;
  }
  return isInvalid;
};

export const isEmailValid = (value) => {
  var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i; // matches Diana.hazalton@dentex.health
  // var pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return pattern.test(value);
};

export const sanatizeMessageBody = (content) => {
  var temp = document.createElement('div');
  temp.innerHTML = content;
  var sanitized = temp.textContent || temp.innerText;
  temp.remove();
  return sanitized;
};

export const getIcon = (src, style) => {
  if (src?.includes('https://') || src?.includes('.svg')) {
    return (
      <img data-testid="avatar-img" src={src} alt="avatar" style={style} />
    );
  } else {
    return (
      <div
        style={{ fontSize: '18px', ...style }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(src) }}
      />
    );
  }
};

// const padTo2Digits = (num) => {
//   return num.toString().padStart(2, '0');
// }

export const convertToHoursAndMinutes = (totalMinutes) => {
  if (!totalMinutes) {
    return 0;
  }
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  if (hours === 0) {
    return `${Math.round(minutes)} min`;
  } else {
    if (minutes === 0) {
      return `${hours} hour`;
    } else {
      return `${hours} hour ${Math.round(minutes)} min`;
    }
  }
};

export const handleScroll = (childRef, parentRef) => {
  setTimeout(() => {
    const child = childRef?.current;
    const parent = parentRef?.current;
    // if (cardRef.current.offsetTop >= parentRef.current.clientHeight) {
    const distanceToTop = child?.offsetTop - (parent?.offsetTop + 50);
    // Scroll parent element to child element
    parent.scrollTop = distanceToTop;
    // }
  }, 100);
};

export const handleScrollIntoview = (id, block) => {
  setTimeout(() => {
    var element = document.getElementById(id);
    element.scrollIntoView({
      block: block,
      behavior: 'smooth'
    });
  }, 300);
};

export const formatMessage = (message) => {
  if (message) {
    return message.replaceAll('*', '');
  }
  return '';
};

export const getInitialLetter = (text) => {
  if (!isEmpty(text) && typeof text === 'string') return text[0].toUpperCase();
  return '';
};

export const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];
  const x = toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
  return x;
};

export const isMobile = () => {
  return window.innerWidth <= 500;
};

export const isMac = () => {
  return window.innerWidth <= 1680;
};

export const getObjectEntries = (obj) => {
  var ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i);
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
};

export const handlePlanType = (data) => {
  if (!data) return false;
  if (
    (typeof data === 'string' || data.length !== 0) &&
    typeof parseInt(data) === 'number'
  ) {
    return true;
  } else {
    return false;
  }
};

export const removeWhiteSpace = (value) => {
  if (value) {
    return value.replace(/\s+/g, ' ').trim();
  }
};

export const formatNumber = (value) => {
  if (value) {
    let addPlus = value.replace('(', '+');
    return addPlus.replace(')', '');
  }
};

export const formatWhatsAppTemplates = (value, array) => {
  if (!isEmpty(value) && !isEmpty(array)) {
    let tempValue = value;
    for (let i = 0; i <= array.length; i++) {
      tempValue = tempValue.replace(`{{${i + 1}}}`, array[i]);
    }
    return tempValue;
  }
};

export const isInt = (value) => {
  var pattern = /^\d*\.?\d*$/;
  return pattern.test(value);
};

export const JSONparse = (value) => {
  if (value) return JSON.parse(value);
  return null;
};
