import { createSlice } from '@reduxjs/toolkit';
import { GET_OR_UPDATE_NUDEGES, MY_INFO } from 'urls';
import APIRequest from 'apiRequest';
import { clearStore } from 'store/store';
import { clearAuthToken } from 'slices/authSlice';
import { setCurrentPlan } from 'slices/subscription/slice';
import { replaceUrl } from 'utils/urlReplace';

export const myInfoSlice = createSlice({
  name: 'myInfo',
  initialState: {
    isLoading: true,
    isError: false,
    isSuccess: false,
    myInformation: null,
    isNudgeActive: false,
    isChannelsConnected: false,
    subOrganizationId: null,
    infoCount: 0,
    isHideWebformGuide: false,
    sender_custom_email: null
  },
  reducers: {
    setMyInformation: (state, action) => {
      state.infoCount = state.infoCount + 1;
      state.myInformation = action?.payload;
      state.isChannelsConnected =
        action?.payload.sub_organizational_ids[0]?.is_any_channel_connected;
      state.subOrganizationId =
        action?.payload.sub_organizational_ids[0]?.sub_organization_id;
      if (action?.payload?.sender_emails) {
        state.sender_custom_email = action?.payload.sender_emails[0];
      } else {
        state.sender_custom_email = null;
      }
    },
    updateShowVideoPreference: (state, action) => {
      const { videoId, preference } = action.payload;
      state.myInformation.show_these_videos_to_users[videoId] = preference;
    },
    setIsChannelsConnected: (state, action) => {
      state.isChannelsConnected = action?.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setUserInformation: (state, action) => {
      state.myInformation = action.payload;
    },
    setIsNudgeActive: (state, action) => {
      state.isNudgeActive = action.payload;
    },
    setIsHideWebformGuide: (state, action) => {
      state.isHideWebformGuide = action.payload;
    },
    updateInitialTasksSuborganization: (state, action) => {
      state.myInformation.initial_tasks_suborganization = action.payload;
    }
  }
});

export const {
  setMyInformation,
  setIsChannelsConnected,
  setIsLoading,
  setIsError,
  setIsSuccess,
  setUserInformation,
  setIsNudgeActive,
  setIsHideWebformGuide,
  updateShowVideoPreference,
  updateInitialTasksSuborganization
} = myInfoSlice.actions;

export default myInfoSlice.reducer;

export const getMyInfo = (callback) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      new APIRequest()
        .get(MY_INFO)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setMyInformation(resp?.data));
            dispatch(setCurrentPlan(resp?.data.plan_details));
            dispatch(setIsHideWebformGuide(resp?.data.hide_web_form_message));
            dispatch(setIsSuccess(true));
            dispatch(setIsLoading(false));
            callback(resp?.data);
          }
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            if (
              err?.response?.data?.code === 'user_not_found' ||
              err?.response?.data?.code === 'token_not_valid'
            ) {
              clearStore();
              dispatch(clearAuthToken());
            }
          }
          dispatch(setIsLoading(false));
          dispatch(setIsError(true));
          dispatch(setIsSuccess(false));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNudges = (payload) => {
  return async (dispatch) => {
    try {
      const { userId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_OR_UPDATE_NUDEGES, 'userId', userId))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setIsNudgeActive(resp.data.move_enquiry_stages_in_boxes));
          }
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const UpdateNudgeActiveStatus = (payload, onSucess, onError) => {
  return async (dispatch) => {
    try {
      const { userId, data } = payload;
      new APIRequest()
        .put(replaceUrl(GET_OR_UPDATE_NUDEGES, 'userId', userId), data)
        .then((resp) => {
          if (resp.status === 200) {
            onSucess(resp?.data?.move_enquiry_stages_in_boxes);
          }
        });
    } catch (e) {
      console.log(' e', e);
      if (onError) {
        onError();
      }
    }
  };
};
