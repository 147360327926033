export const ApiHeaders = (headers, { getState }) => {
  const token = getState().auth.authToken;
  const signUpToken = getState().auth.signUpAuthToken;
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  } else if (signUpToken) {
    headers.set('Authorization', `Bearer ${signUpToken}`);
  }
  return headers;
};
