import logo from 'assets/icons/boxly/boxly_logo_color.svg';
// import Lottie from 'lottie-react';
// import spinner from '../../lottie/spinner.json';

const LogoLoader = ({ children }) => {
  return (
    // <div className="flex flex-col items-center justify-center h-full h-screen">
    //   <Lottie style={{ height: 50 }} animationData={spinner} />
    //   {children}
    // </div>
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={logo} alt="eb" className="initial-logo-flash" />
      {children}
    </div>
  );
};

export default LogoLoader;
