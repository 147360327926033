import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  ADD_UPDATE_DELETE_LOCATION_LABELS,
  GET_LOCATION_LABELS_LIST,
  UPDATE_LABEL_LOCATION_LEAD_STATUS,
  UPDATE_LABEL_LOCATION_STATUS
} from 'urls/locationLabels';
import { replaceUrl } from 'utils/urlReplace';

export const locationLabels = createSlice({
  name: 'locationLabels',
  initialState: {
    locationLabelsList: [],
    isShowLocationLabelStatus: true,
    selectedLocationLabels: []
  },
  reducers: {
    setLocationLabelList: (state, action) => {
      state.locationLabelsList = action.payload;
    },
    setSelectedLocationLabels: (state, action) => {
      state.selectedLocationLabels = action.payload;
    }
  }
});

export const { setLocationLabelList, setSelectedLocationLabels } =
  locationLabels.actions;

export default locationLabels.reducer;

export const getLocationLabelsList = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_LOCATION_LABELS_LIST, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLocationLabelList(res?.data?.locations));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const addLocationLabelName = (payload, onSuccess) => {
  return async (_, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .post(
          replaceUrl(ADD_UPDATE_DELETE_LOCATION_LABELS, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 201) {
            if (onSuccess) {
              onSuccess();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateLocationLabelName = (payload, onSuccess, onError) => {
  return async (_, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .put(
          replaceUrl(ADD_UPDATE_DELETE_LOCATION_LABELS, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess();
            }
          }
        });
    } catch (e) {
      if (onError) {
        onError();
      }
      console.log(e);
    }
  };
};

export const deleteLocationLabelName = (payload, onSuccess) => {
  return async (_, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .delete(
          replaceUrl(ADD_UPDATE_DELETE_LOCATION_LABELS, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateLocationLabelStatus = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .post(
          replaceUrl(UPDATE_LABEL_LOCATION_STATUS, 'subOrgId', subOrgId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            return res;
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateLeadLocationLabelStatus = (payload, onSuccess) => {
  return async () => {
    const { lead, label } = payload;
    try {
      await new APIRequest()
        .post(replaceUrl(UPDATE_LABEL_LOCATION_LEAD_STATUS, 'leadId', lead), {
          location_id: label
        })
        .then((res) => {
          if (res.status === 200) {
            if (onSuccess) {
              onSuccess(res);
            }
            return res;
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const deleteLeadLocationLabel = (payload, callback) => {
  return async () => {
    const { leadId, locationLabelId } = payload;
    try {
      await new APIRequest()
        .delete(
          replaceUrl(UPDATE_LABEL_LOCATION_LEAD_STATUS, 'leadId', leadId),
          { loacation_id: locationLabelId }
        )
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(res);
            }
            return res;
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
