import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import CHOICES from 'choices';
import { setAttachments } from 'slices/editor/slice';
import {
  GET_ALL_REPLY_TEMPLATE,
  UPDATE_OR_DELETE_REPLY_TEMPLATE
} from 'urls/replyTemplate';
import { replaceUrl } from 'utils/urlReplace';

const { EMAIL } = CHOICES.ReplyTemplateChoice;

const replyTemplateSlice = createSlice({
  name: 'replyTemplate',
  initialState: {
    allReplyTemplates: [],
    activeReplyTemplate: {},
    emailData: '',
    templateName: '',
    subject: '',
    replyType: EMAIL
  },
  reducers: {
    setAllReplyTemplates: (state, action) => {
      state.allReplyTemplates = action.payload;
    },
    setActiveReplyTemplate: (state, action) => {
      state.activeReplyTemplate = action.payload;
    },
    setEmailData: (state, action) => {
      state.emailData = action.payload;
    },
    setTemplateName: (state, action) => {
      state.templateName = action.payload;
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
    },
    setReplyType: (state, action) => {
      state.replyType = action.payload;
    }
  }
});

export const {
  setAllReplyTemplates,
  setActiveReplyTemplate,
  setEmailData,
  setTemplateName,
  setSubject,
  setReplyType
} = replyTemplateSlice.actions;

export default replyTemplateSlice.reducer;

export const getAllReplyTemplates = (payload = {}) => {
  return (dispatch, getState) => {
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      const { callback } = payload;
      new APIRequest()
        .get(replaceUrl(GET_ALL_REPLY_TEMPLATE, 'subOrgId', subOrgId))
        .then((resp) => {
          dispatch(setAllReplyTemplates(resp.data?.results));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createReplyTemplate = (payload) => {
  return (dispatch, getState) => {
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      const { body, callback } = payload;
      new APIRequest()
        .post(replaceUrl(GET_ALL_REPLY_TEMPLATE, 'subOrgId', subOrgId), body)
        .then((resp) => {
          if (callback) {
            callback(resp.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteReplyTemplate = (payload) => {
  return (dispatch, getState) => {
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      const { templateId, callback } = payload;
      new APIRequest()
        .delete(
          replaceUrl(
            UPDATE_OR_DELETE_REPLY_TEMPLATE,
            ['subOrgId', 'templateId'],
            [subOrgId, templateId]
          )
        )
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateReplyTemplate = (payload) => {
  return (dispatch, getState) => {
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      const { body, templateId, callback } = payload;
      new APIRequest()
        .put(
          replaceUrl(
            UPDATE_OR_DELETE_REPLY_TEMPLATE,
            ['subOrgId', 'templateId'],
            [subOrgId, templateId]
          ),
          body
        )
        .then((resp) => {
          if (callback) {
            callback(resp.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const resetData = () => {
  return (dispatch) => {
    dispatch(setTemplateName(''));
    dispatch(setSubject(''));
    dispatch(setEmailData(''));
    dispatch(setAttachments([]));
    dispatch(setReplyType(EMAIL));
  };
};
